<template>
    <div class="flag-container">
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <el-row v-else>
            <el-col class="flag-list" :class="{'min-list':min}" v-for="(m, index) in list">
                <a href="javascript:;" @click.stop="getLink(m)">
                    <el-skeleton :loading="loading" animated :count="1">
                        <template slot="template">
                            <el-skeleton-item variant="image" class="img" />
                            <div class="content">
                                <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                                <el-skeleton-item variant="text" style="width: 50%;" />
                            </div>
                            <div class="bottom flex-bt">
                                <el-skeleton-item variant="text" style="width: 30%;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </template>
                        <template>
                            <div class="flag-box">
                                <div class="img-box">
                                    <el-image class="img" :class="{ 'video-img': m.video }" :src="getImg(m)" fit="cover">
                                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                    </el-image>
                                    <span class="top" v-show="m.top">已置顶</span>
                                </div>
                                <div class="content">
                                    <div>
                                        <div class="title-box">
                                            <div class="title ellipsis-2">{{m.title || clearHtml(m.content) }}</div>
                                            <span class="time">{{m.addtime}}</span>
                                        </div>
                                        <p></p>
                                        <span class="tag" v-if="m.shenhe==-1 && m.reason">原因：{{m.reason}}</span>
                                        <div class="tag-box">
                                            <span class="tag check" v-if="m.shenhe!=1">{{m.shenhe==0? '审核中':'未通过'}}</span>
                                            <span class="tag check" v-if="m.private==1">私密</span>
                                            <span class="tag" @click.stop :key="ti" v-for="(t, ti) in m.topic">
                                                <router-link :to="{ path: '/list', query: {topic:t},}">{{ t }}</router-link>
                                            </span>
                                        </div>
                                        <div class="tag-box">
                                            <span class="tag" @click.stop :key="ti" v-for="(t, ti) in m.topic_experience">
                                                <router-link :to="{ path: '/list', query: {topic:t, authed:1},}">&经历 {{ t }} 已认证</router-link>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="bottom flex-bt">
                                        <div class="num-box flex">
                                            <div class="icon-box">
                                                <i class="el-icon-view"></i>
                                                <span class="see">{{ returnNum(m.visit) }}</span>
                                            </div>
                                            <div class="icon-box" :class="{ active: m.isthumbsup }" @click.stop="zanFlag(m, index)">
                                                <i class="iconfont icon-dianzan"></i>
                                                <span class="see">{{ returnNum(m.thumbsup) }}</span>
                                            </div>
                                            <div class="icon-box" :class="{ active: m.islover }" @click.stop="scFlag(m, index)">
                                                <i class="iconfont icon-jushoucang" :class="{ 'icon-jushoucanggift': m.islover }"></i>
                                                <span class="see">{{ returnNum(m.lover) }}</span>
                                            </div>
                                            <div class="icon-box">
                                                <i class="iconfont icon-xiaoxi"></i>
                                                <span class="see">{{ m.comments || 0 }}</span>
                                            </div>
                                            <div class="icon-box set-box" @click.stop="setOpShow(index, m)">
                                                <span class="see">操作</span>
                                            </div>
                                        </div>
                                        <div class="op-box" @click.stop v-show="!min">
                                            <span class="op" v-if="m.video"><a :download="rImg(m.video)" :href="rImg(m.video)">下载</a></span>
                                            <span class="op" @click="del(m, index)">删除</span>
                                            <span class="op" @click="setTop(m, index)">{{m.top ? '取消置顶':'置顶'}}</span>
                                            <span class="op" @click="setPrivate(m)">{{m.private == 0 ? '公开':'私密'}}</span>
                                            <span class="op" v-if="m.video" @click="showDialog(m, index)">修改</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </a>
            </el-col>
        </el-row>
        <el-drawer append-to-body=true title="选择操作选项" :visible.sync="op.show" direction="btt" custom-class="op-drawer" style="height: auto;">
            <div class="d-op" @click.stop="setOp(0)">删除</div>
            <div class="d-op" @click.stop="setOp(1)">{{op.m.top == 1 ? '取消置顶':'置顶'}}</div>
            <div class="d-op" @click.stop="setOp(2)">{{op.m.private == 1 ? '公开':'私密【仅自己可见】'}}</div>
            <div class="d-op" v-if="op.m.video" @click.stop="setOp(3)">修改</div>
        </el-drawer>
        <el-dialog append-to-body=true title="修改作品描述和封面" :visible.sync="dialog.show" width="40%">
            <div class="dialog-box">
                <p class="d-title">标题：</p>
                <el-input :rows="2" type="textarea" v-model="dialog.m.title" placeholder="填写标题更吸引人哦" maxlength="31" show-word-limit :clearable=true></el-input>
                <p class="d-title">正文：</p>
                <el-input :rows="4" type="textarea" v-model="dialog.m.content" placeholder="填写正文" maxlength="500" show-word-limit :clearable=true></el-input>
                <p class="d-title">封面：</p>
                <div class="img-box" @click="selectFile()">
                    <img :src="rImg(dialog.m.cover)" :onerror="errImg" />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog.show = false">取 消</el-button>
                <el-button type="primary" @click="updateV()">确 定</el-button>
            </span>
        </el-dialog>
        <input type="file" @change="getCover" ref="cover" style="display: none" accept="image/*" />
    </div>
</template>

<script>
    export default {
        name: 'dyList',
        components: {},
        props: ['list', 'min'],
        data() {
            return {
                loading: false,
                myid: '',
                dialog: {
                    show: false,
                    i: 0,
                    m: {}
                },
                op: {
                    show: false,
                    i: 0,
                    m: {}
                }
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.myid = this.$y_getKey('userid');
        },
        methods: {
            // 获取图片
            getImg(m) {
                let img = m.video ? m.cover : m.masterimg[0];
                return this.rImg(img);
            },
            // 选择文件
            selectFile() {
                this.$refs.cover.click();
            },
            // 
            getCover() {
                let files = this.$refs.cover.files;
                this.dialog.m.cover = window.URL.createObjectURL(files[0]);
                this.dialog.m.cover_file = files[0];
            },
            // 显示操作弹窗
            setOpShow(i, m) {
                this.op = {
                    i: i,
                    show: true,
                    m: m,
                }
            },
            // 选择操作
            setOp(t) {
                let _this = this;
                let index = this.op.i;
                let m = this.list[index];
                switch (t) {
                    case 0:
                        _this.del(m, index)
                        break;
                    case 1:
                        _this.setTop(m, index);
                        break;
                    case 2:
                        _this.setPrivate(m);
                        break;
                    case 3:
                        _this.showDialog(m, index);
                        break;
                    default:
                        break;
                }
                _this.op.show = false;
            },
            // 修改
            showDialog(m, i) {
                this.dialog = {
                    i: i,
                    m: {
                        title: m.title,
                        content: m.content,
                        cover: m.cover,
                        cover_file: '',
                        dy_id: m.dy_id
                    },
                    show: true
                }
            },
            async updateV() {
                let m = this.dialog.m;
                this.$y_loading();
                if (m.cover_file) {
                    m.cover = await this.$y_upImg(m.cover_file);
                    delete m.cover_file;
                }
                let ret = await this.$y_ajax('api/Dymanic/update', m);
                this.$y_loadingClose();
                this.$y_msg(ret.msg);
                if (ret && ret.code == 200) {
                    let info = this.list[this.dialog.i];
                    info.title = m.title;
                    info.content = m.content;
                    info.cover = m.cover;
                    info.shenhe = 0;
                    this.dialog.show = false;
                }
            },
            // 置顶
            async setTop(m) {
                let ret = await this.$y_ajax('api/Dymanic/setTop', {
                    dy_id: m.dy_id,
                    state: m.top ? 0 : 1
                })
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    m.top = m.top ? 0 : 1;
                }
            },
            // 隐私
            async setPrivate(m) {
                let ret = await this.$y_ajax('api/Dymanic/setPrivate', {
                    dy_id: m.dy_id,
                    state: m.private ? 0 : 1
                })
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    m.private = m.private ? 0 : 1;
                }
            },
            // 删除动态
            del(m, index) {
                let _this = this;
                let c = this.clearHtml(m.content);
                c = c.substr(0, 10);
                this.$y_confirm({
                    msg: `确定删除【${m.title || c}】？`
                }, async (bi) => {
                    if (bi == 1) {
                        let {
                            code,
                            msg
                        } = await _this.$y_ajax('api/Member/delDymanic', {
                            id: m.dy_id
                        })
                        _this.$y_msg(msg);
                        if (code == 200) {
                            _this.list.splice(index, 1);
                        }
                    }
                })
            },
            // 点赞
            async zanFlag(m) {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                let s = parseInt(m.isthumbsup);
                let ret = await this.$y_ajax("api/Dymanic/thumbsup", {
                    dy_id: m.dy_id,
                    type: s + 1, // 1点赞 2取消
                    userid: this.myid
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    let t = m.thumbsup;
                    m.isthumbsup = s == 0 ? 1 : 0;
                    m.thumbsup = s == 0 ? parseInt(t) + 1 : t - 1;
                }
            },
            // 收藏
            async scFlag(m) {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                let s = parseInt(m.islover);
                let ret = await this.$y_ajax("api/Dymanic/lover", {
                    dy_id: m.dy_id,
                    type: s + 1, // 1点赞 2取消
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    m.islover = s == 0 ? 1 : 0;
                }
            },
            // 打开详情
            getLink(m) {
                let u = '';
                if (m.shenhe != undefined && m.shenhe != 1) {
                    this.$y_msg(m.shenhe == -1 ? '未通过' : '审核中')
                    return;
                }
                switch (m.source_type) {
                    case 1:
                    case 2:
                        u = '/flagdes?id=' + m.source_id;
                        break;
                    case 3:
                        u = '/fwdes?id=' + m.source_id;
                        break;
                    case 4:
                        u = '/actdes?id=' + m.source_id;
                        break;
                    default:
                        u = '/dydes?id=' + m.dy_id;
                        break;
                }
                window.location.href = '#' + u;
                // return u;
            }
        },
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';
    @import '@assets/less/mylist.less';

    .flag-list {
        .img-box {
            position: relative;

            .top {
                position: absolute;
                top: 5px;
                right: 5px;
                background: #49C265;
                border-radius: 5px;
                color: #fff;
                font-size: 12px;
                padding: 5px 7px;
            }
        }

    }

    .min-list {
        .flag-box {
            .title {
                font-size: 14px;
            }
        }


        .img {
            width: 100px;
            height: 70px;
        }

        .video-img::after {
            font-size: 20px;
        }

        .tag-box {
            display: none;
        }

    }

    /* 弹窗 */
    .dialog-box {


        .d-title {
            padding: 10px 0;
        }

        .img-box {
            width: 100px;
            height: 100px;
            cursor: pointer;

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }

    @media (max-width: 600px) {
        .op-box {
            display: none;
        }

        /deep/.el-drawer__body {
            overflow-y: hidden;
            height: 100%;
        }
    }
</style>